h1, h2, h3, h4{
    margin: 0;
    font-family: 'Merriweather', serif;
}
h5,h6{
    margin: 0;
    font-family: 'Poppins', serif;
}
h2{
    text-align: center;
    font-size: clamp(30px, 3.33vw, 48px);
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}
h3{
    font-size: clamp(26px, 2.78vw, 40px);
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
}
h4{
    font-size: clamp(22px, 2.43vw, 35px);
    font-style: normal;
    font-weight: 400;
    line-height: 164.571%;
}
h5{
    font-size: clamp(15px, 1.38vw, 20px);
    font-style: normal;
    font-weight: 500;
    line-height:150% ;
}
h6{
    font-size: clamp(14px, 1.25vw, 18px);
    font-style: normal;
    font-weight: 500;
    line-height:150% ;
}
p,li{
    font-family: 'Poppins', serif;
    font-size: 14.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 234.483%;
}