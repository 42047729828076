.about-essential-section{
    position: relative;
    background: url(../../../assets/images/prayers-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &::before{
        content: '';
        opacity: 0.8;
        background: #000;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        display: block;
    }
    .item-icon{
        svg{
            width: 60%;
        }
    }
}