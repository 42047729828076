.article-items-section{
    border-radius: 20px;
    border: 1px solid #CDCACA;
    overflow: hidden;
    @media screen and (max-width: 568px) {
        border-radius: 10px;
    }

    .section-tabs{
        background: #F9FAFB;
        padding: 0 37px;
        display: flex;
        button{
            padding: 20px 25px;
            color: rgba(0, 0, 0, 0.50);
            text-align: left;
            font-family: 'Poppins';
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 127.273%;
            display: flex;
            align-items: center;
            gap: 14px;
            position: relative;
            &.active{
                color: #FF9822;
                &::before{
                    content: '';
                    width: 10px;
                    height: 10px;
                    border: 1px solid #FF9822;
                    border-radius: 50%;
                    background: transparent;
                    display: block;
                    position: absolute;
                    left: 0;
                }
            }
            &:hover, &.active{
                color: #FF9822;
                &::after{
                    content: '';
                    width: calc(100% - 40px);
                    height: 2px;
                    border-radius: 50px;
                    background: #FF9822;
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            @media screen and (max-width: 568px) {
                padding: 10px 13px;
                font-size: 12px;
                color: #000;
                &.active{
                    padding: 10px 17px;
                    background: #FFB800;
                    color: #fff;
                    &::before{
                        left: 5px;
                        width: 7px;
                        height: 7px;
                        border-color: #fff;
                    }
                }
            }
        }
        @media screen and (max-width: 568px) {
            padding: 0;
            overflow-y: auto;
        }
    }
    .tab-content{
        .file-link-item {
            a{
                display: block;
                width: 100%;
                padding: 35px 36px;
                border-radius: 10px;
                background: rgba(229, 37, 42, 0.10);
                svg{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
                @media screen and (max-width: 568px) {
                    padding: 15px 20px;
                }
            }
            .file-name{
                color: #000;
                font-family: 'Poppins';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                margin-top: 10px;
                max-width: 100%;
                word-break: break-all;
                @media screen and (max-width: 568px) {
                    font-size: 10px;
                }
            }
        }
    }
}