.mainHeaderPane {
    height: calc(100vh - 95px);

    @media only screen and (max-width: 600px) {
        height: calc(100vh - 85px);
        width: 98.5%;
        margin-left: auto;
        margin-right: auto;
    }
}

.mainHeaderPane .chapters-list {
    height: calc(100vh - 168px);

    @media only screen and (max-width: 600px) {
        height: calc(100vh - 155px);
        transition: transform 0.3s ease;
    }

    &.open {
        @media only screen and (max-width: 600px) {
            transform: unset;
            z-index: 6;
            box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2);
            width: 200px;
            padding-top: 40px;
        }
    }
}

.mainHeaderPane .resizeable-list {
    border-right: 1px solid #000;
    background: #fff;

    @media only screen and (max-width: 600px) {
        width: fit-content !important;
        border-right: 0;
    }
}

.mainHeaderPane .chapters-list::-webkit-scrollbar {
    width: 6px;
}

.mainHeaderPane .chapters-list::-webkit-scrollbar-thumb {
    background: #FF9822;
    border-radius: 5px;
}

.mainHeaderPane .content-section::-webkit-scrollbar {
    width: 6px;
}

.mainHeaderPane .content-section::-webkit-scrollbar-thumb {
    background: #FF9822;
    border-radius: 5px;
}

.mainHeaderPane .content-section h1 {
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 700;

    @media only screen and (max-width: 600px) {
        font-size: 1.4rem;
    }
}

.mainHeaderPane .content-section hr {
    width: 50%;
    height: 3px;
    background-color: #FF9822;
    margin: 8px auto;
}

.mainHeaderPane .content-section a[target="footnotes"] {
    color: #551A8B;
    text-decoration: underline;
    display: block;
}
.mainHeaderPane .content-section a[target="suras"] {
    color: #551A8B;
    text-decoration: underline;
}

.mainHeaderPane .content-section a {
    font-size: 14px;
}

.mainHeaderPane .content-section p {
    margin-bottom: 16px;
    line-height: 1.3;
    font-size: 14px;
}

.mainHeaderPane .content-section p:last-of-type {
    margin-bottom: 0px;
}

.mainHeaderPane .content-section p.subtitle {
    margin-bottom: 16px;
}

.mainHeaderPane .content-section p br:nth-of-type(1) {
    display: none;
}

.mainHeaderPane .content-section a br:nth-of-type(1) {
    display: none;
}