.how-to-section {
  .bg-banner-section {
    position: relative;
    background: url(../../../assets/images/how-to-img.png),
      #000000cc 50% / cover no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .prayer-steps {
    .step-item-text {
      border-radius: 0 0 10px 10px;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 40px;
        transform: rotate(-3deg);
        border-radius: 10px;
        background: #ff8800;
        position: absolute;
        left: 0;
        top: -24px;
        z-index: -1;
      }
      .item-no {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        position: absolute;
        background: #4b4b4b;
        left: -10px;
        top: -45px;
        display: flex;
        align-items: center;
        justify-content: center;
        columns: #fff;
        color: #fff;
        text-align: center;
        font-family: "Merriweather";
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
