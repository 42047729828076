.proclamation-item{
    a{
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media screen and (max-width: 568px) {
            font-size: 14px;
        }
    }
    .item-num{
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        background: url(../../assets/images/serial-tag-bg.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}