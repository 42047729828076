.banner-section {
  background: url(../../../assets/images/world-map.jpeg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
  }
}
