.page-banner-section{
    position: relative;
    &::before{
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.6);
        position: absolute;
        z-index: 1;
    }
    div{
        z-index: 2;
    }
}