.info-banner-seciton{
    h4{
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 28px;
        font-weight: 700;
        line-height: 162.5%;
        @media screen and (max-width:568px) {
            font-size: 20px;
        }
    }
    a{
        color: #FFF;
        text-align: center;
        font-size: 20px;
        font-style: italic;
        font-weight: 500;
        line-height:  140%;
        text-decoration-line: underline;
        @media screen and (max-width:568px) {
            font-size: 14px;
        }
    }
}