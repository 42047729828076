.sub-page-content{
    .page-title{
        background: linear-gradient(90deg, #FF9822 0%, rgba(255, 152, 34, 0.00) 28.86%);
        padding: 15px 27px;
        border-left: 5px solid #BD6607;
        h4{
            font-size: clamp(18px, 1.5vw, 23px);
            color: #000;
            font-weight: 600;
        }
    }
    p, li{
        font-size: clamp(14px, 1.25vw, 18px);
        line-height: 1.5;
        margin-bottom: 12px;
    }
    .table-responsive{
        max-width: 100%;
        overflow-x: auto;
    }
}