.videos-con{
    flex-wrap: wrap;
    column-gap: 23px;
    row-gap: 19px;
    .video-item{
        width: calc(33.33% - 16px);
        height: 235px;
        position: relative;
        z-index: 1;
        border-radius: 10px;
        overflow: hidden;
        @media screen and (max-width: 768px) {
            width: calc(50% - 11.5px);
        }
        @media screen and (max-width: 568px) {
            width: 100%;
        }

        &::before{
            content: '';
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.5);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
        }
        
        video{
            width: 100%;
            height: 100%;
            position: relative;
            object-fit: cover;
            object-position: center;
            z-index: 0;
        }
        .play-btn{
            position: absolute;
            width: 60px;
            height: 60px;
            z-index: 3;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            img{
                width: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
        .video-details{
            position: absolute;
            bottom: 10px;
            z-index: 3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 10px 14px 19px;
            
            .video-title{
                color: #FFF;
                font-family: 'Poppins';
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 155.556%;
            }
            .video-duration{
                border-radius: 20px;
                background: #F90;
                width: 99.183px;
                height: 37px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFF;
                font-family: 'Poppins';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 175%;
            }
        }
    }
}